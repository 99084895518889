import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { map as _map } from 'lodash';

// Services
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { AdminAbstractService } from '~/services/api/web/admin/adminAbstractService';


// Models
import { ResponseModel } from '~/models/responseModel';
import { AdminPendingConnectionsModel } from '~/models/adminPendingConnectionsModel';
import { RegisteredUsersModel }  from '~/models/registeredUsersModel';

// RxJS
import { Observable } from 'rxjs';

// Lodash
import { isNil as _isNil } from 'lodash';

// Moment
import * as moment from 'moment';

@Injectable()
export class AdminService extends BaseWebService implements AdminAbstractService {

    public static readonly PATH: string = '/admin';

    public getTransactions(fromDate:moment.Moment, toDate:moment.Moment) : Observable<ResponseModel> {
        let url:string = AdminService.URL + AdminService.PATH + '/transactions';
        let params = new HttpParams();

        if(!_isNil(fromDate)) {
            params = params.append('start', fromDate.format('YYYY-MM-DD'));
        }
        if(!_isNil(toDate)) {
            params = params.append('end', toDate.format('YYYY-MM-DD'));
        }

        let options = {
            params : params,
            responseType: 'blob' as 'blob'
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return response;
       });
    }

    public getBrandConnections() : Observable<ResponseModel> {
        let url:string = AdminService.URL + AdminService.PATH + '/brandconnections';
        let params = new HttpParams();

        let options = {
            params : params,
        };
        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (connections) => new AdminPendingConnectionsModel(connections));
       });
    }

    public getRegisteredUsers(searchTerm:string) : Observable<ResponseModel> {
        let url:string = AdminService.URL + AdminService.PATH + '/registeredusers?searchTerm='+searchTerm.toString();
        let params = new HttpParams();

        let options = {
            params : params,
        };
        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (users) => new RegisteredUsersModel(users));
       });
    }
}
